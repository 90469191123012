/* eslint no-shadow: 0 */

import { SET_BUYER_LOCALIZATION, LOAD_CACHED_BUYER_LOCALIZATION } from 'redux/actions';
import { getAvailableLocations, setLocalizationDetails, getLocalizationDetails } from 'utils/localizationUtils';
import get from 'lodash/get';

/**
 * Updates the localizations details in the local storage
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
const initialState = {
  buyer_region: '',
  buyer_currency: '',
  buyer_locale: '',
  fulfillment_region: '',
  available_locales: [],
  available_currencies: []
};

const processLocalizationState = (state) => {
  const cache = getLocalizationDetails();
  let updatedState = { ...cache, ...state };

  if (!(get(updatedState, 'fulfillment_region', '')).length) {
    const buyerRegion = get(state, 'buyer_region', 'USA');

    let defaultRegion;

    switch (buyerRegion) {
      case 'EUR':
        defaultRegion = 'EU';
        break;
      case 'Canada':
        defaultRegion = 'Canada';
        break;
      case 'AUD':
        defaultRegion = 'Australia';
        break;
      default:
        defaultRegion = 'USA';
        break;
    }

    updatedState = {
      ...updatedState,
      fulfillment_region: defaultRegion
    };
  }

  return updatedState;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BUYER_LOCALIZATION: {
      const newState = Object.assign({}, state, action.data);
      const updated = processLocalizationState(newState);
      setLocalizationDetails(updated);
      return updated;
    }

    case LOAD_CACHED_BUYER_LOCALIZATION: {
      const initialState = getAvailableLocations();
      return processLocalizationState(initialState);
    }
    default:
      return state;
  }
};
