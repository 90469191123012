/*eslint-disable camelcase*/
import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import MainMenu from 'components/MainMenu';
import FlyOutMenu from 'components/FlyOutMenu';
import SocialMediaIcons from 'components/SocialMediaIcons';
import propTypes, { func } from 'prop-types';
import { Icon } from '@springforcreators/propel-ui';
import { setActiveModal } from 'redux/actions';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import tracker from 'utils/tracking';
import { bpProps } from 'utils/responsiveUtils';
import headerTemplates from './headerTemplates';
import HeaderCart from './HeaderCart';
import './Header.scss';

const Header = (props) => {
  const {
    brand,
    content,
    styles,
    meta,
    stores,
    getStyles,
    bpIsGT,
    bpIsLT
  } = props;

  const dispatch = useDispatch();

  const { localizationData } = useSelector(
    state => state
  );

  const { fulfillment_region } =
    localizationData;

  const regionFlags = {
    USA: '/assets/flags/usa.svg',
    EU: '/assets/flags/eu.svg',
    Canada: '/assets/flags/canada.svg',
    Australia: '/assets/flags/aus.svg',
    'Rest of the world': '/assets/flags/rest.svg'
  };

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const hamburgerClasses = hamburgerOpen ? 'is-active' : 'is-inactive';

  if (!stores) return false;

  const { collections } = stores;
  const trackLogoClicked = () => tracker.track('header.logo.clicked');
  const trackAboutClicked = () => tracker.track('header.about.clicked');
  const trackSearchClicked = () => tracker.track('header.search.clicked');

  const customLinks = get(content, 'header.customLinks') || [];

  if (get(content, 'header.showAbout') && !customLinks.find(link => link.name === 'About')) {
    customLinks.push({
      name: 'About',
      slug: 'about',
      action: trackAboutClicked
    });
  }

  const customizer = (objValue, srcValue) => {
    if (isArray(objValue)) return srcValue;
    return undefined;
  };

  const templateStyles = headerTemplates[get(styles, 'header.template')];
  const baseHeaderStyles = get(styles, 'header');

  // Merge header template styles & custom styles into single object
  const headerStyles = mergeWith(templateStyles, baseHeaderStyles, customizer);
  const logoSrc = getStyles('header.logo', content);

  const logoContent = logoSrc && logoSrc !== 'null' // When there is no image passed in to the store preview
    ? (
      <img
        src={ logoSrc }
        alt={ get(meta, 'storeName') }
        style={ { maxHeight: getStyles('logo.maxHeight', headerStyles) } }
      />
    )
    : (
      <h3 style={ getStyles('textStyles', headerStyles) }>
        { get(meta, 'storeName') }
      </h3>
    );

  const showSocialMedia = get(content, 'header.showSocialIcons') && get(brand, 'socialMedia') && get(brand, 'socialMedia').length > 0;

  const exploreMenuItem = { name: 'Explore', slug: '' };
  const menuItems = [exploreMenuItem, ...collections.concat(customLinks)];
  return (
    <>
      <header
        className={ `header universal ${get(headerStyles, 'className') || ''}` }
        style={ {
          ...getStyles('bgStyles', headerStyles),
          ...getStyles('textStyles', headerStyles)
        } }
      >
        <div className={ `${getStyles('constrain', headerStyles) ? 'row' : 'fullrow'}` }>
          <div
            className="header__inner"
            style={ getStyles('container', headerStyles) }
          >
            { (collections.concat(customLinks).length > 0 || showSocialMedia) && (
              <div className="header__hamburger" style={ getStyles('hamburger', headerStyles) }>
                <button
                  type="button"
                  className={ `hamburger-button hamburger-button--minus ${hamburgerClasses}` }
                  onClick={ () => setHamburgerOpen(!hamburgerOpen) }
                >
                  <span className="hamburger-button-box">
                    <span
                      className="hamburger-button-inner"
                      style={ { backgroundColor: getStyles('textStyles.color', headerStyles) } }
                    />
                  </span>
                </button>
                { hamburgerOpen && (
                  <FlyOutMenu
                    menuItems={ menuItems }
                    isOpen={ true }
                    classes="align-left"
                    onFocusBlur={ () => { setHamburgerOpen(false); } }
                    showSocialIcons={ showSocialMedia && bpIsLT('tabletMd') }
                    showSubcategories={ true }
                  />
                ) }
              </div>
            ) }

            <div className="header__logo" style={ getStyles('logo', headerStyles) }>
              { get(content, 'header.logoHref')
                ? <a href={ get(content, 'header.logoHref') } onClick={ trackLogoClicked }>{ logoContent }</a>
                : <Link to="/" onClick={ trackLogoClicked }>{ logoContent }</Link>
              }
            </div>

            { (collections.concat(customLinks).length > 0 && get(content, 'header.showCategories')) && (
              <div className="header__menuitems" style={ getStyles('menuItems', headerStyles) }>
                <MainMenu menuItems={ menuItems } />
              </div>
            ) }

            <div className="header__actions" style={ getStyles('actions', headerStyles) }>
              { (
                showSocialMedia && bpIsGT('tabletMd')
              ) && (
                <SocialMediaIcons
                  styles={ getStyles('socialStyles', headerStyles) }
                  limit={ bpIsLT('desktopSm') ? 3 : null }
                />
              ) }

              { !get(content, 'header.hideSearch') && (
                <Link to="/search" onClick={ trackSearchClicked } className="search-toggle">
                  <Icon name="Search" size={ 18 } />
                </Link>
              ) }
              <button
                type="button"
                style={ { padding: '0 8px' } }
                onClick={ () => dispatch(setActiveModal('localization-modal')) }
              >
                <span>
                  <img
                    src={ regionFlags[fulfillment_region] }
                    alt={ fulfillment_region }
                    style={ { width: '28px', height: '20px', border: 'solid .5px #EFEDE4' } }
                  />
                </span>
              </button>
              <HeaderCart />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

const {
  shape,
  string,
  array,
  bool
} = propTypes;

Header.propTypes = {
  brand: shape({}).isRequired,
  styles: shape({
    header: shape({})
  }).isRequired,
  content: shape({
    header: shape({
      logo: string,
      showSocialIcons: bool
    })
  }).isRequired,
  meta: shape({
    storeName: string
  }).isRequired,
  stores: shape({
    collections: array
  }).isRequired,
  getStyles: func.isRequired,
  bpIsGT: func.isRequired,
  bpIsLT: func.isRequired
};

const mapStateToProps = state => ({
  stores: state.stores,
  brand: get(state, 'themeData.brand'),
  content: get(state, 'themeData.content'),
  styles: get(state, 'themeData.styles'),
  meta: get(state, 'themeData.meta'),
  ...bpProps(state)
});

export default connect(mapStateToProps)(Header);
