/* eslint no-shadow: 0 */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import propTypes from 'prop-types';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import withTracker from 'routes/withTracker';
import PromotionalBanner from 'components/PromotionalBanner';
import Header from 'components/Header';
import Footer from 'components/Footer';
import get from 'lodash/get';
import { setThemeData, setPreviewMode, setBuyerLocalization } from 'redux/actions'; // Ensure setBuyerLocalization is imported
import { getPreviewThemeData } from 'themes';
import tracker from 'utils/tracking';
import './MainLayout.scss';
import { bpProps } from 'utils/responsiveUtils';
import AgeVerify from 'components/AgeVerify';

// Threshold for absolute scroll before hiding the header
// This is primarily to prevent hiding the header when the page
// bounces after over scrolling to the top, which without some
// threshold (or bounce detection) would show and then instantly
// hide the header when enthusiatically scrolling to the top.
const MOBILE_SCROLL_HIDE_THRESHOLD = 15;

const MainLayout = (props) => {
  const {
    activeModal,
    localizationData,
    children,
    location,
    setThemeData,
    setPreviewMode,
    bpIsGT,
    dispatchSetBuyerLocalization // Include dispatch in props
  } = props;
  const {
    storeData,
    themeData,
    initThemeData
  } = useGlobalProps();
  const { content } = themeData;
  const notificationEnabled = get(content, 'notification');

  const fulfillmentRegion = get(localizationData, 'fulfillment_region', 'USA');
  console.log('fulfillmentRegion', fulfillmentRegion); /* eslint-disable-line no-console */

  const passedProps = { storeData, location };
  const pageClass = location.pathname.split('/')[1].toLowerCase();
  const [promoList, setPromoList] = useState(null);

  const ldClient = useLDClient();

  const filterPromoCodes = (promoCodes) => {
    const filteredPromoCodes = promoCodes && promoCodes.filter((promo) => {
      if (promo.code && promo.code !== '' && promo.type && promo.type !== '') {
        if (promo.type === 'free_shipping') {
          return true;
        } else if (promo.type === 'percentage' && promo.value > 0) {
          return true;
        } else if (promo.type === 'fixed_value' && promo.value.amount &&
          promo.value.amount > 0 && promo.value.currency && promo.value.currency === 'USD') {
          return true;
        }
      }
      return false;
    });

    return filteredPromoCodes;
  };

  useEffect(() => {
    const promoCodes = get(storeData, 'promoCodes');
    if (promoCodes && filterPromoCodes(promoCodes).length > 0) {
      tracker.track('whitelabel.promo_banner.viewed');
    }
    setPromoList(filterPromoCodes(promoCodes));
  }, []);

  useEffect(() => {
    const { mergedData, isPreviewMode } = getPreviewThemeData(location, initThemeData);
    setThemeData(mergedData);

    if (isPreviewMode && pageClass.length !== 0) {
      setPreviewMode();
    }
  }, [location, setThemeData, initThemeData]);

  useEffect(() => {
    if (ldClient) {
      const context = {
        kind: 'store',
        key: passedProps.storeData.slug,
        name: passedProps.storeData.name
      };
      ldClient.identify(context, null, () => {});
    }
  }, [ldClient]);

  // Region URL Parameter Logic
  const getRegionFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('region')?.toLowerCase();
  };

  useEffect(() => {
    const regionOptions = localizationData.available_regions.map(curr => ({
      label: curr.region,
      id: curr.fulfillment_region
    }));

    const regionFromURL = getRegionFromURL();

    if (regionFromURL) {
      const regionIndex = regionOptions.findIndex(
        curr => curr.label.toLowerCase() === regionFromURL
      );

      if (regionIndex !== -1) {
        dispatchSetBuyerLocalization({
          fulfillment_region: regionOptions[regionIndex].label
        });

        // Optionally remove the region from the URL after processing
        window.history.replaceState({}, document.title, window.location.pathname);

        // Force refresh the page to ensure the state update takes effect
        window.location.reload();
      }
    }
  }, [dispatchSetBuyerLocalization, localizationData]);

  const isMobile = !bpIsGT('mobileLg');
  const [promoContainerClass, setPromoContainerClass] = useState('');
  // Track whether or not content has scrolled past the top of the page
  const [contentTopVisible, setContentTopVisible] = useState(false);
  const onContentTopWaypointTriggered = useCallback((event) => {
    setContentTopVisible(event?.currentPosition === 'inside');
  });

  const [mobileScrollThresholdMet, setMobileScrollThresholdMet] = useState(false);
  // Track last scroll direction on mobile
  const [mobileScrollDirection, setMobileScrollDirection] = useState('');
  const previousScroll = useRef(0);

  useEffect(() => {
    // We only care about mobile for mobile scroll direction
    if (!isMobile) {
      return undefined;
    }

    const testMobileScrollThresholdMet = () => {
      setMobileScrollThresholdMet(window.scrollY >= MOBILE_SCROLL_HIDE_THRESHOLD);
    };

    const handleScroll = () => {
      if (window.scrollY > previousScroll.current) {
        setMobileScrollDirection('down');
      } else if (window.scrollY < previousScroll.current) {
        setMobileScrollDirection('up');
      }
      previousScroll.current = window.scrollY;
      testMobileScrollThresholdMet();
    };

    // Set initial scroll threshold
    testMobileScrollThresholdMet();

    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('touchmove', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true });
      window.removeEventListener('touchmove', handleScroll, { passive: true });
      // Note we leave the current scroll direction value for the next potential mobile breakpoint
    };
  }, [isMobile]);

  // Determine if a modal is be being shown.
  // The 'object' check is a workaround for an issue in Modal (propel-ui) where it's setting the
  // active modal to an object (containing the id of null) when trying to close the modal.
  // See https://amazesw.atlassian.net/browse/DIS-144
  const activeModalId = activeModal?.id;
  const modalDisplayed = activeModalId && typeof activeModalId !== 'object';

  // Set the 'hidden' promo container class if we last scrolled down on mobile, content has been
  // scrolled off screen, we've passed the scroll distance threshold, and a modal is not being
  // diplayed (the modal check is for dialogs attached to the header, like quick add confirmation)
  useEffect(() => {
    if (isMobile && mobileScrollDirection === 'down' && mobileScrollThresholdMet && !contentTopVisible && !modalDisplayed) {
      setPromoContainerClass('hidden');
    } else {
      setPromoContainerClass('');
    }
  }, [isMobile, mobileScrollDirection, mobileScrollThresholdMet, contentTopVisible, modalDisplayed]);

  if (!get(themeData, 'styles')) {
    return false;
  }

  return (
    <div className={ `page-content ${pageClass} ${fulfillmentRegion}` }>
      <AgeVerify />
      <div className={ `notification-promo-container ${promoContainerClass}` }>
        { notificationEnabled && promoList && promoList.length > 0 && <PromotionalBanner promoCodeList={ promoList } /> }
        <Header { ...passedProps } />
        { !notificationEnabled && promoList && promoList.length > 0 && <PromotionalBanner promoCodeList={ promoList } /> }
      </div>
      <Waypoint
        onEnter={ onContentTopWaypointTriggered }
        onLeave={ onContentTopWaypointTriggered }
      />
      {children}
      <Footer { ...passedProps } />
    </div>
  );
};

const {
  string,
  arrayOf,
  number,
  bool,
  shape,
  array,
  node,
  oneOf,
  func
} = propTypes;

MainLayout.propTypes = {
  location: shape({
    hash: string,
    pathname: string,
    search: string
  }),
  localizationData: shape({
    available_currencies: array,
    available_regions: array,
    available_locales: array
  }).isRequired,
  children: node.isRequired,
  storeData: shape({
    banner_url: string,
    collections: arrayOf(shape({})),
    description: string,
    link_color: string,
    logo_height: number,
    logo_url: string,
    logo_width: number,
    name: string,
    social_identities: shape({}),
    theme_color: string,
    url: string,
    use_logo: bool,
    location: shape({})
  }).isRequired,
  customHeader: node,
  customFooter: node,
  bpIsGT: func.isRequired,
  setThemeData: func.isRequired,
  setPreviewMode: func.isRequired,
  activeModal: shape({
    id: oneOf([string, null, undefined]) // We only care about id
  }),
  dispatchSetBuyerLocalization: func.isRequired
};

MainLayout.defaultProps = {
  location: {},
  customHeader: null,
  customFooter: null,
  activeModal: undefined
};

const mapStateToProps = state => ({
  ...bpProps(state),
  activeModal: state.activeModal,
  localizationData: state.localizationData
});

const mapDispatchToProps = dispatch => ({
  setThemeData: data => dispatch(setThemeData(data)),
  setPreviewMode: () => dispatch(setPreviewMode()),
  dispatchSetBuyerLocalization: data => dispatch(setBuyerLocalization(data))
});

export default withTracker(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
